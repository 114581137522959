
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import variables from "@/router/api";

export default defineComponent({
  name: "motor-certificate",
  components: {},
  data() {
    return {
      isVisible: false
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Motor Certificate", [
        "Policy",
        "Policy Information"
      ]);
    });
  },
  created() {
    //Set page title
    document.title =
      "Policy Information: Motor Certificate | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    printPage(el) {
      variables.printPage(el);
    }
  }
});
